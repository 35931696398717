<template>
    <user-dashboard-layout>
        <v-container fluid class="fill-height p-5">
            <v-row>
                <v-col>
                    <v-card  class="mx-auto my-auto" >
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <avatar-edit
                                        :current_url="company.logo_url"
                                        @input="handleUpdateCompany"
                                        v-model="company.logo_url"
                                    ></avatar-edit>

                                </v-col>
                                <v-col>
                                    <v-form>
                                        <v-container>
                                            <v-row>
                                                <v-col>
                                                    <v-text-field v-model="company.name" label="Name"
                                                                  placeholder=""></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-btn @click="handleUpdateCompany">Update</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import Company from "../../../models/Company";
import AvatarEdit from "../../../components/AvatarEdit";

export default {
    name: "Profile",
    components: {AvatarEdit, UserDashboardLayout},
    data: function () {
        return {
            company: {},
        }
    },
    methods: {
        async handleUpdateCompany() {
            let company = new Company(this.company)
            await company.save()
        }
    },
    mounted() {
        this.company = this.$auth.user().company
    }
}
</script>

<style scoped>

</style>
